<template>
<div class="d-flex align-center justify-center flex-column" style="height: calc(100vh - 150px)">
  <img
      style="width: 100px"
      src="/img/no-results.png" >
  <div class="font-weight-bold text-body-1">
    موردی برای نمایش وجود ندارد.
  </div>
</div>
</template>

<script>
export default {
  name: "EmptyList"
}
</script>

<style scoped>

</style>